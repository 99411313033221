import FixedHeader from "../components/FixedHeader";
import Notification from "../components/Notification";
import logoparque from "../img/logoparque.png";
import Block3Columns from "../components/Block3Columns";
import Block4Columns from "../components/Block4ColumnsText";
import Block2ColumnsText from "../components/Block2ColumnsText";
import ModalFixed from "../components/ModalFixed";

import icon1 from "../img/icons/innovacion.png";
import icon2 from "../img/icons/tecnologias.png";
import icon3 from "../img/icons/biotecnologia.png";
import icon4 from "../img/icons/industria.png";
import icon5 from "../img/icons/aprendizaje.png";

import juan_pablo from "../img/icons/juan_pablo.jpg";

import laboratorios from "../img/icons/investigacion.gif";
import publicaciones from "../img/icons/publicacion.gif";
import grupos from "../img/icons/grupos.gif";

import BlockLeftImage from "../components/BlockLeftImage";

import logo1 from "../img/icons/Recurso 3.png";
import logo2 from "../img/icons/Recurso 2.png";
import logo3 from "../img/icons/Recurso 13.png";
import logo4 from "../img/icons/Recurso 14.png";
import logo5 from "../img/icons/Recurso 15.png";
import logo6 from "../img/icons/Recurso 6.png";
import logo7 from "../img/icons/Recurso 5.png";
import quali from "../img/icons/QUALIPHARM.png";
import tikee from "../img/icons/Tikee.png";
import quo from "../img/icons/QUOHUB.png";

import logon1 from "../img/icons/Recurso 1.png";
import logon2 from "../img/icons/Recurso 16.png";
import logon3 from "../img/icons/Recurso 9.png";
import ile from "../img/icons/ile.png";
import favorita from "../img/icons/la_favorita.png";
import comercio from "../img/icons/comercio.png";
import funeraria from "../img/icons/funeraria.png";
import malca from "../img/icons/malca.png";
import cafrilosa from "../img/icons/cafrilosa.jpg";
import ecolac from "../img/icons/ecolac.png";

import red1 from "../img/icons/Recurso 10.png";
import red2 from "../img/icons/Recurso 7.png";
import agro from "../img/icons/agro.jpg";
import cedia from "../img/icons/cedia.png";

import parque1 from "../img/icons/Recurso 4.png";
import parque2 from "../img/icons/Recurso 8.png";
import BlockRightImage from "../components/BlockRightImage";

function ParqueCientifico() {
  let mystyle = {
    backgroundImage: `url(${logoparque})`,
  };

  return (
    <>
      <FixedHeader
        video="https://investigacion.utpl.edu.ec/sites/default/files/videos/V%C3%ADdeo_tomas_utpl.mp4"
        src="https://utpl.edu.ec/recursos/img/cabecera.jpg"
        span0={
          <>
            UTPL <b>Parque</b>
          </>
        }
        span3="Científico y"
        span4="Tecnológico"
      ></FixedHeader>

      <div style={mystyle} className="intro-parquecientifico padding2">
        <Notification
          content={
            <>
              <h2>
                Somos un espacio de <b>innovación</b> y <b>emprendimiento</b>{" "}
                para impulsar la
                <b> investigación</b>, el <b>desarrollo tecnológico</b> y la{" "}
                <b>transferencia de conocimiento.</b>
              </h2>
            </>
          }
        ></Notification>
      </div>

      <div className="padding graybg">
        <div className="layout8">
          <h2 className="width700">
            Fomentamos la{" "}
            <b>
              colaboración entre las empresas, gobierno, academia y sociedad
              civil
            </b>{" "}
            para contribuir a la creación de un{" "}
            <b>ecosistema de innovación sólido y dinámico.</b>
          </h2>
          <h4>
            Enfocamos nuestras prioridades en los <b>siguientes sectores:</b>
          </h4>
        </div>

        <div className="icon100 padding">
          {" "}
          <Block3Columns
            col1={
              <center>
                <img src={icon1}></img>
                <h4>
                  Innovación <b>alimentaria</b>{" "}
                </h4>
              </center>
            }
            col2={
              <center>
                <img src={icon2}></img>
                <h4>
                  Tecnologías de la <b>información y comunicación</b>
                </h4>
              </center>
            }
            col3={
              <center>
                <img src={icon3}></img>
                <h4>
                  Biotecnología y <b>farmacéutica</b>{" "}
                </h4>
              </center>
            }
          ></Block3Columns>
          <Block2ColumnsText
            col1={
              <center>
                <img src={icon4}></img>
                <h4>
                  Industria cultural y <b>creativa</b>
                </h4>
              </center>
            }
            col2={
              <center>
                <img src={icon5}></img>
                <h4>
                  Tecnologías innovadoras<b> para el aprendizaje</b>
                </h4>
              </center>
            }
          ></Block2ColumnsText>
        </div>
      </div>
      <BlockLeftImage
        src="https://eventos.utpl.edu.ec/sites/default/files/images/foto_parque.png"
        content={
          <>
            <h2>
              Incubación y <b>aceleración de startups</b>{" "}
            </h2>
            <h3>
              Ofrecemos a empresas y emprendimientos el espacio y las
              <b>
                {" "}
                herramientas de investigación y desarrollo de nuevos productos,{" "}
              </b>
              para que así puedan llevar su idea de negocio a la realidad.
            </h3>
            <center>
              {" "}
              <Block3Columns
                col1={
                  <>
                    <h3>
                      <b>483</b>
                    </h3>
                    <p>emprendimientos incubados </p>
                  </>
                }
                col2={
                  <>
                    <h3>
                      <b>195</b>
                    </h3>
                    <p>emprendimientos graduados</p>
                  </>
                }
                col3={
                  <>
                    <h3>
                      <b>729</b>
                    </h3>
                    <p>postulaciones en programas a nivel nacional</p>
                  </>
                }
              ></Block3Columns>
              <a target="_blank" href="https://prendho.com/">
                Revisa aquí nuestra convocatoria
              </a>
            </center>
          </>
        }
      ></BlockLeftImage>

      <div className="relative">
        <div className="layout8 padding">
          <center>
            <h2>
              Vinculación <b>empresa – universidad</b>{" "}
            </h2>

            <h3>
              Te ayudamos a potenciar tu empresa con{" "}
              <b>
                espacios de coworking, laboratorios, acceso a redes de contactos
                nacionales e internacionales
              </b>{" "}
              y la conformación de nodos.
            </h3>

            <h4 className="width500">
              <b>Más de 20 empresas</b> ya forman parte de nuestro ecosistema de
              investigación y emprendimiento.{" "}
            </h4>
          </center>

          <h4>
            Empresas <b>residentes</b>
          </h4>
          <div className="icon100 flex-center grayicon">
            <div>
              {" "}
              <img src={logo1}></img>
            </div>
            <div>
              {" "}
              <img src={logo2}></img>
            </div>
            <div>
              {" "}
              <img src={logo3}></img>
            </div>
            <div>
              {" "}
              <img src={logo4}></img>
            </div>
            <div>
              {" "}
              <img src={logo5}></img>
            </div>
            <div>
              {" "}
              <img src={logo6}></img>
            </div>
            <div>
              {" "}
              <img src={logo7}></img>
            </div>
            <div>
              {" "}
              <img src={quali}></img>
            </div>
            <div>
              {" "}
              <img src={tikee}></img>
            </div>
            <div>
              {" "}
              <img src={quo}></img>
            </div>
          </div>
          <h4>
            Empresas <b>aliadas</b>
          </h4>
          <div className="icon100 flex-center grayicon">
           

            <div>
              <img src={logon1}></img>
            </div>
            <div>
              <img src={logon2}></img>
            </div>
            <div>
              <img src={logon3}></img>
            </div>
            <div>
              <img src={ile}></img>
            </div>
            <div>
              <img src={favorita}></img>
            </div>
            <div>
              <img src={comercio}></img>
            </div>
            <div>
              <img src={funeraria}></img>
            </div>
            <div>
              <img src={malca}></img>
            </div>
            <div>
              <img src={cafrilosa}></img>
            </div>
            <div>
              <img src={ecolac}></img>
            </div>
          </div>
          <div className="margin"></div>
        </div>

        <ModalFixed
          title="Esfuerzos conjuntos transforman la Industria de Alimentos en Ecuador 🥙"
          color="#2a4898"
          content={
            <>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/otV8QCodk4M?si=sSkxka05N13LacG1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </>
          }
        ></ModalFixed>
      </div>
      <div className="graybg">
        <BlockRightImage
          src="https://eventos.utpl.edu.ec/sites/default/files/images/Dise%C3%B1o%20sin%20t%C3%ADtulo%20(3).png"
          content={
            <>
              <h2>
                Formación permanente para el{" "}
                <b>desarrollo integral de las personas</b>
              </h2>

              <p>
                Ofrecemos una amplia variedad de{" "}
                <b>programas académicos y cursos de formación permanente</b>{" "}
                diseñados para preparar a profesionales que puedan enfrentar
                desafíos científicos y tecnológicos.
              </p>
              <a href="/formacionpermanente">Conoce la oferta</a>
            </>
          }
        ></BlockRightImage>
      </div>
      <div className="padding ">
        <div className="layout8">
          <center>
            {" "}
            <h2>
              ¿Qué nos <b>diferencia?</b>
            </h2>
          </center>
        </div>
        <div className="icon100 padding2">
          <center>
            <Block3Columns
              col1={
                <>
                  <img src={laboratorios}></img>
                  <h3>
                    <b>79 laboratorios</b>
                  </h3>
                  <p>
                    para generar y transferir conocimiento desde el sur del
                    Ecuador
                  </p>
                  <a
                    className="button"
                    target="_blank"
                    href=" https://investigacion.utpl.edu.ec/laboratorios"
                  >
                    Conócelos
                  </a>
                </>
              }
              col2={
                <>
                  {" "}
                  <img src={grupos}></img>
                  <h3>
                    <b>73 grupos de investigación </b>
                  </h3>
                  <p>para trabajar en red</p>
                  <a
                    className="button"
                    target="_blank"
                    href="https://investigacion.utpl.edu.ec/grupos"
                  >
                    Conócelos
                  </a>
                </>
              }
              col3={
                <>
                  <img src={publicaciones}></img>
                  <h3>
                    <b>+ 2600 publicaciones </b>
                  </h3>
                  <p>científicas indexadas en Scopus</p>
                </>
              }
            ></Block3Columns>
          </center>
        </div>
      </div>
      <div className="bluebg padding">
        <div className="layout8">
          {" "}
          <h2 className="width500">
            Gestión y <b>transferencia de conocimiento</b>
          </h2>
          <h3 className="width500">
            Apoyamos el{" "}
            <b>
              registro de tus descubrimientos, la validación de productos o
              servicios con el sector empresarial
            </b>{" "}
            y te asesoramos en el registro de tu propiedad intelectual de
            acuerdo con tu giro de negocio.
          </h3>
        </div>
        <center>
          <Block4Columns
            col1={
              <>
                <h3>
                  <b>205</b>{" "}
                </h3>
                <p>
                  registros de propiedad intelectual reconocidos nacional e
                  internacionalmente{" "}
                </p>
              </>
            }
            col2={
              <>
                <h3>
                  <b>67</b>{" "}
                </h3>
                <p>patentes</p>
              </>
            }
            col3={
              <>
                <h3>
                  <b>82</b>{" "}
                </h3>
                <p>
                  {" "}
                  derechos de autor sobre software, base de datos y obras
                  literarias
                </p>
              </>
            }
            col4={
              <>
                <h3>
                  <b>56</b>
                </h3>
                <p>
                  {" "}
                  56 secretos industriales de registros transferidos al sector
                  productivo
                </p>
              </>
            }
          ></Block4Columns>
        </center>
      </div>

      <div className="padding">
        <center>
          <h2 className="width500">
            Confiamos en el poder de las conexiones globales para{" "}
            <b>impulsar nuestro impacto</b>
          </h2>
          <p className="layout6">
            Nos enlazamos con redes internacionales y colaboramos con parques
            científicos de prestigio para fortalecer nuestra innovación y
            emprendimiento. Estas alianzas estratégicas enriquecen nuestra
            investigación y desarrollo, ofreciendo un entorno propicio para la
            creatividad y el crecimiento.
          </p>
        </center>
        <div className="layout8">
          <center>
            {" "}
            <h3>
              Nuestras <b>redes</b>
            </h3>
          </center>
          <div className="icon100 flex-center grayicon">
            <div>
              {" "}
              <img src={red1}></img>
            </div>

            <div>
              {" "}
              <img src={red2}></img>
            </div>
            <div>
              {" "}
              <img src={agro}></img>
            </div>
            <div>
              {" "}
              <img src={cedia}></img>
            </div>
          </div>
        </div>

        <div className="layout8 padding2 grayicon">
          <center>
            {" "}
            <h3>
              Parques Científicos <b>aliados</b>
            </h3>
          </center>
          <div className="icon100 flex-center">
            <div>
              {" "}
              <img src={parque1}></img>
            </div>
            <div>
              {" "}
              <img src={parque2}></img>
            </div>
          </div>
        </div>
      </div>

      <div>
        <BlockLeftImage
          src={juan_pablo}
          content={
            <>
              <p>
                En el Parque Científico y Tecnológico de la UTPL, creemos en el
                poder transformador de la investigación. Conectamos a
                investigadores, emprendedores y empresas para convertir ideas
                innovadoras en soluciones reales. Nuestro objetivo es impulsar
                el desarrollo económico y social de la región, generando
                oportunidades.
              </p>
              <p> Dr. Juan Pablo Suárez Chacón</p>
              <p>
                <b> Director Parque Científico y Tecnológico UTPL</b>
              </p>
            </>
          }
        ></BlockLeftImage>
      </div>
    </>
  );
}

export default ParqueCientifico;
